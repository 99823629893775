import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { addModal, removeModal } from '@state-mgmt/slices/appSlice';

import { ResetAppModal } from '@components/ResetAppModal';

export const useResetAppModal = () => {
  const modalKey = 'reset-app-modal';
  const dispatch = useDispatch();

  const onClose = useCallback(() => {
    dispatch(removeModal({ key: modalKey }));
  }, [dispatch]);

  const displayModal = useCallback(
    (clearApp: () => void) => {
      const children = (
        <ResetAppModal
          onConfirm={() => {
            clearApp();
            onClose();
          }}
          onClose={onClose}
        />
      );
      dispatch(addModal({ key: modalKey, props: { visible: true, children, onClose } }));
    },
    [dispatch, onClose]
  );

  return { displayModal };
};
