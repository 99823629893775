/** @jsxImportSource @emotion/react */
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';

import { useAppTheme } from '@hooks/useAppTheme';
import { RootState } from '@state-mgmt/store';
import { styles } from './styles';

export interface Props {
  onClose: () => void;
  onConfirm: () => void;
}

const ResetAppModal = ({ onClose, onConfirm }: Props) => {
  const { t } = useTranslation();
  const theme = useAppTheme({});
  const { themeMode } = useSelector((state: RootState) => state.app);

  return (
    <div css={styles.container(themeMode)}>
      <DialogTitle>{t('reset-app-modal.title')}</DialogTitle>
      <DialogContent>
        <Typography css={styles.message}>{t('reset-app-modal.message')}</Typography>
      </DialogContent>
      <DialogActions css={styles.actionsContainer(theme)}>
        <Button css={styles.actionsBtn} variant="outlined" onClick={onClose}>
          {t('reset-app-modal.cancel-button')}
        </Button>
        <Button css={styles.actionsBtn} variant="contained" onClick={onConfirm}>
          {t('reset-app-modal.confirm-button')}
        </Button>
      </DialogActions>
    </div>
  );
};

export default ResetAppModal;
